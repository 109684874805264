import { MyOptionType } from "components/Inputs/AsyncCreatableSelect";
import basePath from "lib/helpers/basePath";

interface Option {
  label: string;
  value: string | number;
  icon: string;
}

export const convertArrOfStringsToAcceptableOptions = (arrOfStrings: Array<string>): Array<MyOptionType> => arrOfStrings.map((str) => ({ label: str, value: str }));

export const countryOptions: Array<MyOptionType> = [
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const courseTypeOptions = [
  { value: true, label: "STEM" },
  { value: false, label: "Non-STEM" },
];

export const refiEmiTenureOptions = [
  { label: "5 years", value: 60 },
  { label: "7 years", value: 84 },
];

export const usEmploymentOptions: Array<Option> = [
  {
    label: "Have an offer",
    value: "FUTURE_EMPLOYMENT",
    icon: `${basePath}/assets/images/offer.svg`,
  },
  {
    label: "Student",
    value: "UNEMPLOYED",
    icon: `${basePath}/assets/images/unemployed.svg`,
  },
];

export const landingPageEmploymentStatusOptions: Array<Option> = [
  {
    label: "Working Professional",
    value: "EMPLOYED",
    icon: `${basePath}/assets/images/offer.svg`,
  },
  {
    label: "Student",
    value: "UNEMPLOYED",
    icon: `${basePath}/assets/images/unemployed.svg`,
  },
];

export const employmentStatusOptionsNew = [
  {
    label: "Employed",
    value: "EMPLOYED",
  },
  {
    label: "Have an offer",
    value: "FUTURE_EMPLOYMENT",
  },
  {
    label: "Student",
    value: "UNEMPLOYED",
  },
];

export const activeLoanStatusOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const companyOrConsultantOptions: Array<MyOptionType> = [
  { label: "Company", value: "COMPANY" },
  { label: "Consultant", value: "CONSULTANCY" },
];

export const yesNoOptions: Array<MyOptionType> = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const addressProofOptions: Array<MyOptionType> = [
  {
    label: "US Bank Statement",
    value: "BANK_STATEMENT",
  },
  {
    label: "Rent or lease deed",
    value: "RENT_LEASE",
  },
  {
    label: "Driving licence",
    value: "DRIVING_LICENCE",
  },
  {
    label: "Phone bill",
    value: "PHONE_BILL",
  },
];

export const cosignerAddressProofOptions: Array<MyOptionType> = [
  {
    label: "Ration Card",
    value: "RATION_CARD",
  },
  {
    label: "Post-paid telephone bill",
    value: "TELEPHONE_BILL",
  },
  {
    label: "Gas Bill / Receipt",
    value: "GAS_BILL",
  },
  {
    label: "Electricity Bill",
    value: "ELECTRICITY_BILL",
  },
  {
    label: "Water Bill",
    value: "WATER_BILL",
  },
];
export const maleFemaleOptions: Array<MyOptionType> = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export const cosignerRelationshipOptions: Array<MyOptionType> = [
  { label: "Father", value: "FATHER" },
  { label: "Mother", value: "MOTHER" },
  {
    label: "Grandfather",
    value: "GRANDFATHER",
  },
  {
    label: "Grandmother",
    value: "GRANDMOTHER",
  },
  { label: "Brother", value: "BROTHER" },
  { label: "Sister", value: "SISTER" },
  { label: "Uncle", value: "UNCLE" },
  { label: "Aunt", value: "AUNT" },
  { label: "Cousin", value: "COUSIN" },
  { label: "Other", value: "OTHER" },
];

export const noOfDependantsOptions: Array<MyOptionType> = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "More than 10", value: 11 },
];
export const numberOptions: Array<MyOptionType> = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "More than 10", value: 11 },
];

export const patOptions: Array<MyOptionType> = [
  {
    label: "1 - 10 lakhs",
    value: "A",
  },
  {
    label: "10 - 25 lakhs",
    value: "B",
  },
  {
    label: "25 - 50 lakhs",
    value: "C",
  },
  {
    label: "More than 50 lakhs",
    value: "D",
  },
];

export const residenceTypeOptions: Array<MyOptionType> = [
  { label: "Rented", value: "RENTED" },
  { label: "Owned", value: "OWNED" },
];
export const employmentStatusOptions: Array<MyOptionType> = [
  { label: "Salaried", value: "SALARIED" },
  {
    label: "Self-Employed",
    value: "SELFEMPLOYED",
  },
];
export const workExperienceOptions: Array<MyOptionType> = [
  {
    label: "Less than 5 years",
    value: 30,
  },
  { label: "5 - 10 years", value: 90 },
  {
    label: "10 - 20 years",
    value: 180,
  },
  {
    label: "More than 20 years",
    value: 246,
  },
];

export const referenceRelationshipOptions: Array<MyOptionType> = [
  { label: "Father", value: "FATHER" },
  { label: "Mother", value: "MOTHER" },
  {
    label: "Grandfather",
    value: "GRANDFATHER",
  },
  {
    label: "Grandmother",
    value: "GRANDMOTHER",
  },
  {
    label: "Brother",
    value: "BROTHER",
  },
  { label: "Sister", value: "SISTER" },
  { label: "Uncle", value: "UNCLE" },
  { label: "Aunt", value: "AUNT" },
  { label: "Cousin", value: "COUSIN" },
  { label: "Other", value: "OTHER" },
];
export const indianStates = [
  {
    label: "Andhra Pradesh",
    value: "andhra_pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "arunachal_pradesh",
  },
  {
    label: "Assam",
    value: "assam",
  },
  {
    label: "Bihar",
    value: "bihar",
  },
  {
    label: "Chhattisgarh",
    value: "chhattisgarh",
  },
  {
    label: "Goa",
    value: "goa",
  },
  {
    label: "Gujarat",
    value: "gujarat",
  },
  {
    label: "Haryana",
    value: "haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "himachal_pradesh",
  },
  {
    label: "Jharkhand",
    value: "jharkhand",
  },
  {
    label: "Karnataka",
    value: "karnataka",
  },
  {
    label: "Kerala",
    value: "kerala",
  },
  {
    label: "Madhya Pradesh",
    value: "madhya_pradesh",
  },
  {
    label: "Maharashtra",
    value: "maharashtra",
  },
  {
    label: "Manipur",
    value: "manipur",
  },
  {
    label: "Meghalaya",
    value: "meghalaya",
  },
  {
    label: "Mizoram",
    value: "mizoram",
  },
  {
    label: "Nagaland",
    value: "nagaland",
  },
  {
    label: "Odisha",
    value: "odisha",
  },
  {
    label: "Punjab",
    value: "punjab",
  },
  {
    label: "Rajasthan",
    value: "rajasthan",
  },
  {
    label: "Sikkim",
    value: "sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "tamil_nadu",
  },
  {
    label: "Telangana",
    value: "telangana",
  },
  {
    label: "Tripura",
    value: "tripura",
  },
  {
    label: "Uttarakhand",
    value: "uttarakhand",
  },
  {
    label: "Uttar Pradesh",
    value: "uttar_pradesh",
  },
  {
    label: "West Bengal",
    value: "west_bengal",
  },
  {
    label: "Andaman & Nicobar Islands",
    value: "andaman_and_nicobar_islands",
  },
  {
    label: "Chandigarh",
    value: "chandigarh",
  },
  {
    label: "Dadar and Nagar Haveli",
    value: "dadar_and_nagar_haveli",
  },
  {
    label: "Daman and Diu",
    value: "daman_and_diu",
  },
  {
    label: "Delhi",
    value: "delhi",
  },
  {
    label: "Jammu & Kashmir",
    value: "jammu_and_kashmir",
  },
  {
    label: "Ladakh",
    value: "ladakh",
  },
  {
    label: "Lakshadweep",
    value: "lakshadweep",
  },
  {
    label: "Punducherry",
    value: "punducherry",
  },
];
export const usStates = [
  {
    label: "Alabama",
    value: "alabama",
  },
  {
    label: "Alaska",
    value: "alaska",
  },
  {
    label: "Arizona",
    value: "arizona",
  },
  {
    label: "Arkansas",
    value: "arkansas",
  },
  {
    label: "California",
    value: "california",
  },
  {
    label: "Colorado",
    value: "colorado",
  },
  {
    label: "Connecticut",
    value: "connecticut",
  },
  {
    label: "Delaware",
    value: "delaware",
  },
  {
    label: "Florida",
    value: "florida",
  },
  {
    label: "Georgia",
    value: "georgia",
  },
  {
    label: "Hawaii",
    value: "hawaii",
  },
  {
    label: "Idaho",
    value: "idaho",
  },
  {
    label: "Illinois",
    value: "illinois",
  },
  {
    label: "Indiana",
    value: "indiana",
  },
  {
    label: "Iowa",
    value: "iowa",
  },
  {
    label: "Kansas",
    value: "kansas",
  },
  {
    label: "Kentucky",
    value: "kentucky",
  },
  {
    label: "Louisiana",
    value: "louisiana",
  },
  {
    label: "Maine",
    value: "maine",
  },
  {
    label: "Maryland",
    value: "maryland",
  },
  {
    label: "Massachusetts",
    value: "massachusetts",
  },
  {
    label: "Michigan",
    value: "michigan",
  },
  {
    label: "Minnesota",
    value: "minnesota",
  },
  {
    label: "Mississippi",
    value: "mississippi",
  },
  {
    label: "Missouri",
    value: "missouri",
  },
  {
    label: "Montana",
    value: "montana",
  },
  {
    label: "Nebraska",
    value: "nebraska",
  },
  {
    label: "Nevada",
    value: "nevada",
  },
  {
    label: "New Hampshire",
    value: "new_hampshire",
  },
  {
    label: "New Jersey",
    value: "new_jersey",
  },
  {
    label: "New Mexico",
    value: "new_mexico",
  },
  {
    label: "New York",
    value: "new_york",
  },
  {
    label: "North Carolina",
    value: "north_carolina",
  },
  {
    label: "North Dakota",
    value: "north_dakota",
  },
  {
    label: "Ohio",
    value: "ohio",
  },
  {
    label: "Oklahoma",
    value: "oklahoma",
  },
  {
    label: "Oregon",
    value: "oregon",
  },
  {
    label: "Pennsylvania",
    value: "pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "rhode_island",
  },
  {
    label: "South Carolina",
    value: "south_carolina",
  },
  {
    label: "South Dakota",
    value: "south_dakota",
  },
  {
    label: "Tennessee",
    value: "tennessee",
  },
  {
    label: "Texas",
    value: "texas",
  },
  {
    label: "Utah",
    value: "utah",
  },
  {
    label: "Vermont",
    value: "vermont",
  },
  {
    label: "Virginia",
    value: "virginia",
  },
  {
    label: "Washington",
    value: "washington",
  },
  {
    label: "West Virginia",
    value: "west_virginia",
  },
  {
    label: "Wisconsin",
    value: "wisconsin",
  },
  {
    label: "Wyoming",
    value: "wyoming",
  },
];
export const scoreBase: Array<MyOptionType> = [
  {
    label: "4",
    value: 4,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "100",
    value: 100,
  },
];

export const admissionTestOptions = [
  { label: "GRE", value: "GRE" },
  { label: "GMAT", value: "GMAT" },
  { label: "Waived off", value: "WAIVED_OFF" },
];

export const languageTestOptions = [
  { label: "TOEFL", value: "TOEFL" },
  { label: "IELTS", value: "IELTS" },
];

export const languageTestOptionsEmployedFlow = [
  ...languageTestOptions,
  { label: "Duolingo", value: "DUOLINGO" },
];
